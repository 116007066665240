import Typography from 'typography'
import github_theme from 'typography-theme-github'

github_theme.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    body: {
      color: `rgb(0,0,0)`,
    },
  }
}

delete github_theme.googleFonts

const typography = new Typography(github_theme)

// Segoe UI, SegoeUI, Segoe WP, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
